import { createActionGroup, props } from '@ngrx/store';
import { UserModel } from '../../_models/user.model';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Login Auth': props<{ username: string; password: string }>(),
    'Login Auth Success': props<{ token: string; status: boolean }>(),
    'Load User Auth': props<any>(),
    'Load User Auth Success': props<{ user: UserModel }>(),
    'Logout Auth': props<any>(),
    'Forgot Auth': props<{ email: string }>(),
    'Forgot Auth Success': props<any>(),
    'Reset Password Auth': props<{ hash: string; password: string }>()
  },
});
